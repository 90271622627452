<template>
  <div class="home">
    <router-view/>
    <van-tabbar v-model="active" @change="onChange" >
      <van-tabbar-item icon="home-o" name="home">
        <span>招工大厅</span>
        <template #icon="props">
          <img :src="props.active ? icon.indexActive : icon.indexInActive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="friends-o" name="demandList" >
        <span>为你推荐</span>
        <template #icon="props">
          <img :src="props.active ? icon.maaActive : icon.maaInActive" />
        </template>
      </van-tabbar-item>
<!--      <van-tabbar-item icon="user-o" name="news">-->
<!--        <span>消息 </span>-->
<!--        <template #icon="props">-->
<!--          <img :src="props.active ? icon.newsActive : icon.newsInActive" />-->
<!--        </template>-->
<!--      </van-tabbar-item>-->
      <van-tabbar-item icon="user-o" name="demand">
        <span>发布信息</span>
        <template #icon="props">
          <img :src="props.active ? icon.addInActive : icon.addActive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="user-o" name="user">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? icon.userActive : icon.userInActive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import store from '../store'
	import {
		mapMutations
	} from 'vuex'
	import {
		SET_TABBAR
	} from '../const.js'
export default {
  name: "basicLayout",
  data() {
    return {
      active: 'home',
      icon:{
        indexActive: require('../assets/tabbar/index-active.png'),
        indexInActive: require('../assets/tabbar/index-inactive.png'),
        maaActive: require('../assets/tabbar/maa-active.png'),
        maaInActive: require('../assets/tabbar/maa-inactive.png'),
        newsActive: require('../assets/tabbar/maa-inactive1.png'),
        newsInActive: require('../assets/tabbar/maa-inactive1.png'),
        userActive: require('../assets/tabbar/user-active.png'),
        userInActive: require('../assets/tabbar/user-inactive.png'),
        addActive: require('../assets/tabbar/add-active.png'),
        addInActive: require('../assets/tabbar/add-inactive.png'),
      }
    };
  },
  created() {
     this.active = this.$route.name

  },
  methods:{
    onChange(name){
      console.log("name"+JSON.stringify(name))
      this.active=name;
      this.$router.push({name})
    }
  },
  watch:{
    '$route'(v){
      console.log('router',v)
      this.active = v.name
    }
  }
};
</script>

<style>

</style>
